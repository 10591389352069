import Web3 from "web3/dist/web3.min.js";
import detectEthereumProvider from "@metamask/detect-provider";
import lockABI1 from "./IERC20.json";
async function newInitContractIcon(token) {
  const Provider = await detectEthereumProvider();
  const web3 = new Web3(Provider);
  const abi = new web3.eth.Contract(lockABI1, token);
  return abi.methods;
}

export const balance = async (address, token) => {
   const instance = await newInitContractIcon(token)
   return instance.balanceOf(address).call()
}



