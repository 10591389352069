<template>
    <div class="flexPage themeColor">
        <navbar :params="pageInfo" />
        <div class="title">Function</div>
        <img src="../../assets/img/web.png" alt="" style="width: 120px;height: 120px;margin: 0 auto;margin-top: 92px;">
        <div class="btn" @click="login()">{{ $t('other.translate152') }}</div>

        <van-dialog v-model="showModifyStop" :title="$t('other.translate154')" :show-cancel-button="false"
            :showConfirmButton="false">
            <div
                style="margin-top: 24px;padding: 0 24px;display: flex;justify-content: space-around;align-items: center;">
                <input class="inputs" :lang="$i18n.locale" v-model="number" :placeholder="$t('other.translate156')" />
                <div class="btnA" @click="all">{{ $t('other.translate155') }}</div>
            </div>
            <div
                style="width: 286px;display: flex;justify-content: flex-end;align-items: center;margin: 0 auto; margin-top: 5px;">
                {{ $t('trade.use') }}: {{ balance }}
            </div>

            <div class="btnBox">
                <div class="btnC" @click="$router.push({ name: 'home' });">{{ $t('button.otc.cancel') }}</div>
                <div class="btnS" @click="recharge">{{ $t('button.otc.sure') }}</div>
            </div>
        </van-dialog>
    </div>
</template>

<script>
import navbar from '@/views/modules/navbar.vue';
import { getAddress, getSignRes } from '../../utils/address';
import { balance } from '../../web3/abi';
export default {
    components: { navbar },
    data() {
        return {
            pageInfo: {
                customTitle: '',
                border: false,
                navbarStyle: 'normal'
            },
            showModifyStop: false,
            number: '',
            balance: ''
        }
    },
    created() {
        
    },
    methods: {
        async login() {
            const res = await getAddress()
            const signRes = await getSignRes(res)
            this.$http.post(this.host + `/uc/addressLogin?S=${signRes}&address=${res}`).then((response) => {
                if (response.body.code == 0) {
                    this.$toast(this.$t("validate.signin.success"));
                    this.$store.commit('setLoginStatus', true);
                    localStorage.setItem("token", response.body.data.token);
                    localStorage.setItem("user", JSON.stringify(response.body.data));
                    this.$store.commit('setMember', response.body.data);
                    this.showModifyStop = true
                    this.getBalance()
                } else {
                    this.$toast(response.data.message);
                }
            })
        },
        async getBalance() {
            const res = await getAddress()
            const res1 = await balance(res, '0x4b051BBEeCB59d8521d89fDeFE8E5db116f9B6Af')
            this.balance = Number(String(res1 / Math.pow(10, 18)).replace(/^(.*\..{4}).*$/, "$1"))
        },
        async all() {
            const res = await getAddress()
            const res1 = await balance(res, '0x4b051BBEeCB59d8521d89fDeFE8E5db116f9B6Af')
            this.number = Number(String(res1 / Math.pow(10, 18)).replace(/^(.*\..{4}).*$/, "$1"))
        },
        async recharge() {
            if(!this.number){
                this.$toast(this.$t('other.translate171')); 
            }else if(this.number == 0 || this.number < 0 || this.number % 1 != 0){
                this.$toast(this.$t('other.translate174'))
            }
            this.$http.post(this.host + `/uc/legal-wallet-recharge/loginRecharge?amount=${this.number}&unit=USDT`).then((response) => {
                console.log("000", response);
                if (response.body.code == 0) {
                    this.$toast(this.$t("other.translate160"));
                    this.$router.push({ name: "home" });
                } else {
                    this.$toast(response.data.message);
                }
            })
        }
    }
}
</script>

<style scoped>
::v-deep .van-dialog__header {
    font-family: Inter;
    font-size: 4.5802vw;
    font-weight: 600;
}

.btnBox {
    width: 54.9618vw;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 18.4504vw;
}

.btnC {
    height: 9.1603vw;
    width: 25.4453vw;
    font-family: Inter;
    font-size: 3.5623vw;
    font-weight: 600;
    line-height: 9.1603vw;
    text-align: center;
    color: #00000080;
    border: .2545vw solid #00000080;
    border-radius: 1.0178vw
}

.btnA {
    height: 9.1603vw;
    width: 20.4453vw;
    font-family: Inter;
    font-size: 3.5623vw;
    font-weight: 600;
    line-height: 9.1603vw;
    text-align: center;
    color: #00000080;
    border: .2545vw solid #00000080;
    border-radius: 1.0178vw;
    margin-left: 2.0356vw;
}

.btnS {
    height: 9.1603vw;
    width: 25.4453vw;
    font-family: Inter;
    font-size: 3.5623vw;
    font-weight: 600;
    line-height: 9.1603vw;
    text-align: center;
    background: #0052FF;
    color: #fff;
    border-radius: 1.0178vw
}

::v-deep .van-dialog {
    width: 84.9873vw;
    height: 71.7557vw;
    border-radius: 4.0712vw;
}

::v-deep .van-field__label {
    padding-top: 1.7812vw !important
}

::v-deep .van-dialog__footer {
    border: none !important;
    margin-top: 17.5573vw
}

.inputs:lang(zh-CN) {
    height: 9.1603vw !important;
    border: .2545vw solid #0000001A !important;
    border-radius: 1.0178vw !important;
    padding-left: 8px;
    width: 246px;
}

.inputs:lang(en-US) {
    height: 9.1603vw !important;
    border: .2545vw solid #0000001A !important;
    border-radius: 1.0178vw !important;
    padding-left: 8px;
    width: 200px;
}

.btn {
    width: 327px;
    height: 40px;
    background: #000000;
    border-radius: 8px;
    margin: 0 auto;
    margin-top: 78px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 40px;
    text-align: center;
    color: #fff
}

.title {
    width: 100%;
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
}
</style>